import React from 'react';
import Loadable from 'react-loadable';
import { store,persistor }from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import LazyLoader from './LazyLoader';
import { createGenerateClassName, StylesProvider } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  seed: 'selynk',
});

const LoadableComponent = Loadable({
  loader: () => import('./config/Navigation'),
  loading: LazyLoader,
});

class App extends React.Component {
  render() {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
          <LoadableComponent />
          </PersistGate>
        </Provider>
      </StylesProvider>
    );
  }
}

export default App;